import app from "./app";
import {
	getAuth,
	signOut as _signOut,
	sendSignInLinkToEmail as _sendSignInLinkToEmail,
	isSignInWithEmailLink as _isSignInWithEmailLink,
	signInWithEmailLink as _signInWithEmailLink,
	onAuthStateChanged as _onAuthStateChanged,
	onIdTokenChanged as _onIdTokenChanged,
	connectAuthEmulator,
} from "firebase/auth";

import type { User as _User } from "firebase/auth";
import { isDevelopment } from "@wwc/utils";

export type User = _User;
export type UserWithClaims<Claims = Record<string, unknown>> = User & {
	customClaims?: Claims;
	disabled: boolean;
};

const auth = getAuth(app);

if (isDevelopment()) {
	connectAuthEmulator(auth, "http://localhost:9099");
}

export const signOut = _signOut.bind(undefined, auth);
export const sendSignInLinkToEmail = _sendSignInLinkToEmail.bind(
	undefined,
	auth
);
export const isSignInWithEmailLink = _isSignInWithEmailLink.bind(
	undefined,
	auth
);
export const signInWithEmailLink = _signInWithEmailLink.bind(undefined, auth);
export const onAuthStateChanged = _onAuthStateChanged.bind(undefined, auth);
export const onIdTokenChanged = _onIdTokenChanged.bind(undefined, auth);
