import app from "./app";
import {
	connectFunctionsEmulator,
	getFunctions,
	httpsCallable as _httpsCallable,
} from "firebase/functions";
import { isDevelopment } from "@wwc/utils";

const fn = getFunctions(app);

if (isDevelopment()) {
	connectFunctionsEmulator(fn, "localhost", 5001);
}

type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;

export function httpsCallable<RequestData = unknown, ResponseData = unknown>(
	...rest: DropFirst<Parameters<typeof _httpsCallable>>
) {
	return _httpsCallable<RequestData, ResponseData>(fn, ...rest);
}
