import app from "./app";
import {
	initializeFirestore,
	collection as _collection,
	collectionGroup as _collectionGroup,
	connectFirestoreEmulator,
} from "firebase/firestore";

import type { CollectionReference, DocumentData } from "firebase/firestore";
import { isDevelopment } from "@wwc/utils";

type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;

export * from "firebase/firestore";

export const db = initializeFirestore(app, {
	ignoreUndefinedProperties: true,
});

if (isDevelopment()) {
	connectFirestoreEmulator(db, "localhost", 8080);
}

export function collection<T = DocumentData>(
	...rest: DropFirst<Parameters<typeof _collection>>
) {
	return _collection(db, ...rest) as CollectionReference<T>;
}

export function collectionGroup<T = DocumentData>(
	...rest: DropFirst<Parameters<typeof _collectionGroup>>
) {
	return _collectionGroup(db, ...rest) as CollectionReference<T>;
}
